
import { Component, Vue } from 'vue-property-decorator';
import { Promised } from 'vue-promised';
import { AxiosError } from 'axios';
import GButton from '@/components/gsk-components/GskButton.vue';
import GDialog from '@/components/gsk-components/GskDialog.vue';
import MaxWidth from '@/components/MaxWidth.vue';
import DeleteDialog from '@/components/dialogs/DeleteDialog.vue';
import ValidatedFormDialog from '@/components/dialogs/ValidatedFormDialog.vue';
import { openErrorSnackbar, openSnackbar, sanitize } from '@/utils/components';
import { getRegistrationList, setRegistrationProvisioned } from '@/api/service-management';
import Grid from '@/components/grid/Grid';
import GridCell from '@/components/grid/GridCell';


@Component({
  components: {
    GButton,
    MaxWidth,
    Promised,
    ValidatedFormDialog,
    DeleteDialog,
    GDialog,
    Grid,
    GridCell,
  },
})
export default class ServiceManagementView extends Vue {
  dataLoader: Promise<unknown> | null = null;
  dialogOpen = false;
  serviceIdToSetProvisioned: string | null = null;
  public sanitizeLink = (source:string) => sanitize(source);
    
  setProvisionConfirmed(): void {
    if (this.serviceIdToSetProvisioned === null) {
      console.warn('ServiceId cannot be null'); // eslint-disable-line
      return;
    }
    console.log('Set PROVISIONED', this.serviceIdToSetProvisioned); // eslint-disable-line

    setRegistrationProvisioned(this.serviceIdToSetProvisioned)
      .then(res => {
        console.log('set provisioned successfull', res); // eslint-disable-line
        this.serviceIdToSetProvisioned = null;

        openSnackbar.call(this, 'Service provisioned');
        this.load();
      })
      .catch((e: AxiosError) => {
        openErrorSnackbar.call(this, 'Operation failed: ' + e?.response?.statusText);
        console.log(e); // eslint-disable-line
      });
  }

  setProvisionedClicked(serviceId: string): void {
    this.serviceIdToSetProvisioned = serviceId;
    this.dialogOpen = true;
    return;
  }

  load(): void {
    this.dataLoader = getRegistrationList().then(r => r.data);
  }

  fmtDate(d: string) {
    return new Date(d).toLocaleString();
  }
  created(): void {
    this.load();
  }
}
