import { AxiosPromise } from 'axios';
import axios from '@/api/service';
import URLs from '@/api/service/urls';
import { PendingRegistrationDto } from '@/types/service-management-types';

export function getRegistrationList(): AxiosPromise<PendingRegistrationDto[]> {
  return axios.get(URLs.ServiceManagementRegistrationList, {});
}

export function setRegistrationProvisioned(serviceId: string): AxiosPromise<boolean> {
  return axios.put(URLs.ServiceManagementSetRegistrationProvisioned, {
    serviceId: serviceId,
  });
}
